import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

class EtapaProyectoService {
	static Listar = () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Etapa_Proyecto_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_cat_etapa_proyecto: null,
			id_cat_area_proyecto: form.id_cat_area_proyecto || '',
			etapa: form.etapa || '',
			descripcion: form.descripcion || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Etapa_Proyecto_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_cat_etapa_proyecto: form.id_cat_etapa_proyecto,
			id_cat_area_proyecto: form.id_cat_area_proyecto || '',
			etapa: form.etapa || '',
			descripcion: form.descripcion || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Etapa_Proyecto_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_cat_etapa_proyecto) => {
		let params = {
			id_cat_etapa_proyecto: id_cat_etapa_proyecto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Etapa_Proyecto_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default EtapaProyectoService;
