import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SolicitudPagoService} from "../../../../services/_Sis/SolicitudPagoService/SolicitudPagoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance,
	HdrWeakOutlined,
	Person,
	Comment,
	FiberManualRecord,
	AccountTree,
	DeleteOutlined,
	AddOutlined, AttachMoney, CreditCard
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Zoom from '@material-ui/core/Zoom/index';
import {DropzoneArea} from 'material-ui-dropzone';

class ModalSolicitudAceptada extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_solicitud_pago: props.item.id_solicitud_pago || '',
			no_recibo: '',
			id_usuario: '',
			id_cat_area_proyecto: '',
			descripcion: '',
			id_proveedor: '',
			beneficiario: '',
			cantidad: '',
			precio_unitario: '',
			importe: '',
			id_cat_tipo_pago: '',
			factura: '',
			id_cat_banco: '',
			tarjeta: '',
			usuario: '',
			banco: '',
			proveedor: '',
			tipo_pago: '',
			comentarios: '',
			code: '',
			
			partidas: [],
			cat_usuario: [],
			cat_tipo_pago: [],
			cat_banco: [],
			cat_area_proyecto: [],
			cat_proveedor: [],
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	getNoRecibo = () => {
		SolicitudPagoService.NoRecibo(this.state).then((response) => {
			this.setState({no_recibo:response.data, code: response.data });
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	}
	
	save = () => {
		if (this.state.id_solicitud_pago > 0) {
			SolicitudPagoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			SolicitudPagoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
			});
		});
		
		this.props.ListUsuario().then((response) => {
			this.setState({
				cat_usuario: response.data,
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
			});
		});
		
		this.props.ListTipoPago().then((response) => {
			this.setState({
				cat_tipo_pago: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
			});
		});
		
		
		this.setState({
			id_solicitud_pago: '',
			no_recibo: '',
			id_cat_area_proyecto: '',
			comentarios: '',
			partidas: [],
			
			activo: true
		});
		
		this.getNoRecibo();
		if(!FieldsJs.inArray([1,2], this.Usr.id_cat_tipo_usuario)) {
			this.setState({
				id_usuario: this.Usr.id_usuario
			});
		};
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
			});
		});
		
		this.props.ListUsuario().then((response) => {
			this.setState({
				cat_usuario: response.data,
				id_usuario: item.id_usuario || ''
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
			});
		});
		
		this.props.ListTipoPago().then((response) => {
			this.setState({
				cat_tipo_pago: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || ''
			});
		});
		
		
		this.setState({
			id_solicitud_pago: item.id_solicitud_pago || '',
			no_recibo: item.no_recibo || '',
			comentarios: item.comentarios || '',
			partidas: item.partidas || [],
			
			activo: true
		});
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
			});
		});
		
		this.props.ListUsuario().then((response) => {
			this.setState({
				cat_usuario: response.data,
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
			});
		});
		
		this.props.ListTipoPago().then((response) => {
			this.setState({
				cat_tipo_pago: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
			});
		});
		
		
		this.setState({
			id_solicitud_pago: item.id_solicitud_pago || '',
			no_recibo: item.no_recibo || '',
			id_usuario: item.id_usuario || '',
			id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			comentarios: item.comentarios || '',
			partidas: item.partidas || [],
			
			activo: true
		});
		
		this.open();
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el contacto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.lista_contactos);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						lista_contactos: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	agregarPartida = () => {
		try {
			
			/*if (!this.state.clave_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave Concepto.'
				})
			}*/
			
			let partidas = FieldsJs.Copy(this.state.partidas);
			//let productos = FieldsJs.Copy(this.state.listar_concepto);
			
			/*for (let i = 0; i < cat_impuesto.length; i++) {
				if (Number(3) === Number(cat_impuesto[i].id_cat_impuesto)) {
					im = cat_impuesto[i].impuesto;
				}
			}
			*/
			let item = {
				descripcion: this.state.descripcion || '',
				id_proveedor: this.state.id_proveedor || '',
				cantidad: this.state.cantidad || '',
				precio_unitario: this.state.precio_unitario || '',
				importe: this.state.importe || '',
				id_cat_tipo_pago: this.state.id_cat_tipo_pago || '',
				factura: this.state.factura || '',
				id_cat_banco: this.state.id_cat_banco || '',
				tarjeta: this.state.tarjeta || '',
				banco: this.state.banco || '',
				proveedor: this.state.proveedor || '',
				beneficiario: this.state.beneficiario || '',
				tipo_pago: this.state.tipo_pago || '',
			};
			
			partidas.push(item);
			
			
			this.setState({
				
				descripcion: '',
				id_proveedor: '',
				cantidad: '',
				precio_unitario: '',
				importe: '',
				id_cat_tipo_pago: '',
				factura: '',
				id_cat_banco: '',
				tarjeta: '',
				banco: '',
				proveedor: '',
				beneficiario: '',
				tipo_pago: '',
				partidas: partidas,
			});
			
			setTimeout(() => {
				let desglose = this.calcularDesgoseTotales();
				this.concatTipoPago();
				
				this.setState({
					desglose: desglose
				});
			}, 800);
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	concatTipoPago = () => {
		setTimeout(() => {
			let numero = this.state.code;
			let pro = this.state.cat_area_proyecto.find((option) => Number(this.state.id_cat_area_proyecto) === Number(option.id_cat_area_proyecto));
			if (pro) {
				numero = pro.area_proyecto.substr(0, 4) + numero;
			}
			let partidas = FieldsJs.Copy(this.state.partidas);
			for (let i = 0; i < partidas.length; i++) {
				let tipo = this.state.cat_tipo_pago.find((option) => Number(partidas[i].id_cat_tipo_pago) === Number(option.id_cat_tipo_pago));
				if (tipo) {
					numero = tipo.tipo_pago.substr(0, 4) +'-'+ numero;
				}
			}
		
			this.setState({
				no_recibo: numero
			});
		}, 900);
	}
	
	calcularDesgoseTotales = () => {
	
	}
	
	modificarPartida = () => {
		try {
			
			if (!this.state.clave_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_unidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Unidad de Medida.'
				})
			}
			
			if (!this.state.cantidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!this.state.precio_unitario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Valor Unitario.'
				})
			}
			
			if (FieldsJs.inArray([2, 3], Number(this.state.tipo_descuento)) && !this.state.descuento) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descuento.'
				})
			}
			
			if (!this.state.id_cat_objeto_impuesto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Objeto Impuesto.'
				})
			}
			
			let impuestos_t = FieldsJs.Field(this.state.impuestos_traladados) ? FieldsJs.Copy(this.state.impuestos_traladados) : [];
			
			if (Number(this.state.id_cat_objeto_impuesto) === 2 && !this.state.iva_incluido && impuestos_t.length <= 0) {
				throw Object({
					status: false, mensaje: 'Debe agregar un impuesto.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.partidas);
			let productos = FieldsJs.Copy(this.state.listar_concepto);
			let unidades_medida = FieldsJs.Copy(this.state.listar_unidad_medida);
			
			let impuestos_r = FieldsJs.Copy(this.state.impuestos_retenidos);
			let impuestos_trasladados_total = 0;
			let impuestos_retenidos_total = 0;
			let descripcion = '';
			let clave = '';
			let unidad_medida = '';
			let clave_unidad = '';
			let unidad = '';
			let importe = 0;
			if (this.state.iva_incluido) {
				importe = (Number(this.state.precio_unitario) / 1.16) * Number(this.state.cantidad);
				let cat_impuesto_t = FieldsJs.Copy(this.state.listar_cat_impuestos_trasladados);
				let cat_tipo_factor = FieldsJs.Copy(this.state.listar_cat_tipo_factor);
				let cat_impuesto = FieldsJs.Copy(this.state.listar_cat_impuestos);
				
				let it = '';
				let ifc = '';
				let im = '';
				
				for (let i = 0; i < cat_impuesto_t.length; i++) {
					if (Number(2) === Number(cat_impuesto_t[i].id_cat_impuesto_trasladado)) {
						it = cat_impuesto_t[i].impuesto_trasladado;
					}
				}
				for (let i = 0; i < cat_tipo_factor.length; i++) {
					if (Number(1) === Number(cat_tipo_factor[i].id_cat_tipo_factor)) {
						ifc = cat_tipo_factor[i].tipo_factor;
					}
				}
				for (let i = 0; i < cat_impuesto.length; i++) {
					if (Number(3) === Number(cat_impuesto[i].id_cat_impuesto)) {
						im = cat_impuesto[i].impuesto;
					}
				}
				
				let item = {
					id_cat_impuesto_trasladado: 2,
					impuesto_trasladado: it,
					tipo_impuesto: 1,
					tipo_factor: ifc,
					id_cat_impuesto: 3,
					tasa_cuota: im,
				};
				
				impuestos_t.push(item);
			} else if (this.state.iva_aplicado) {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
			} else {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
				
			}
			let descuento = Number(this.state.tipo_descuento) === 2 ? importe * (Number(this.state.descuento) / 100) : Number(this.state.descuento);
			
			for (let i = 0; i < impuestos_t.length; i++) {
				impuestos_t[i].base = importe - descuento;
				impuestos_t[i].importe = (importe - descuento) * (impuestos_t[i].tasa_cuota);
				impuestos_trasladados_total = impuestos_trasladados_total + ((importe - descuento) * (impuestos_t[i].tasa_cuota));
				
			}
			for (let i = 0; i < impuestos_r.length; i++) {
				impuestos_r[i].base = importe - descuento;
				impuestos_r[i].importe = (importe - descuento) * (impuestos_r[i].tasa_cuota);
				impuestos_retenidos_total = impuestos_retenidos_total + ((importe - descuento) * (impuestos_r[i].tasa_cuota));
			}
			
			for (let i = 0; i < productos.length; i++) {
				if (Number(this.state.id_empresa_clave_producto) === Number(productos[i].id_empresa_clave_producto)) {
					clave = productos[i].clave_producto_servicio;
					descripcion = productos[i].descripcion;
				}
			}
			
			for (let i = 0; i < unidades_medida.length; i++) {
				if (Number(this.state.id_empresa_clave_unidad) === Number(unidades_medida[i].id_empresa_clave_unidad)) {
					unidad_medida = unidades_medida[i].concepto ? unidades_medida[i].clave_unidad + ' - ' + unidades_medida[i].concepto : unidades_medida[i].concepto;
					clave_unidad = unidades_medida[i].clave_unidad;
					unidad = unidades_medida[i].concepto;
				}
			}
			
			let item = {
				id_empresa_clave_producto: this.state.id_empresa_clave_producto,
				numero_identificacion: this.state.numero_identificacion,
				id_empresa_clave_unidad: this.state.id_empresa_clave_unidad,
				unidad_medida: unidad_medida,
				clave_unidad: clave_unidad,
				unidad: unidad,
				descripcion: descripcion,
				cantidad: this.state.cantidad,
				precio_unitario: this.state.iva_incluido ? importe : this.state.precio_unitario,
				importe: importe,
				concepto: this.state.concepto,
				clave_concepto: this.state.clave_concepto,
				tipo_descuento: this.state.tipo_descuento,
				descuento: descuento,
				numero_pedimento: this.state.numero_pedimento,
				numero_predial: this.state.numero_predial,
				id_cat_objeto_impuesto: this.state.id_cat_objeto_impuesto,
				trasladados: impuestos_trasladados_total,
				retenidos: impuestos_retenidos_total,
				impuestos_trasladados: impuestos_t,
				impuestos_retenidos: impuestos_r,
				iva_incluido: this.state.iva_incluido ? 1 : 0,
				iva_aplicado: this.state.iva_aplicado ? 1 : 0,
			};
			
			partidas[Number(this.state.editar_partida) - 1] = item;
			
			this.setState({
				
				id_cat_clave_producto_servicio: null,
				id_empresa_clave_unidad: '',
				id_empresa_clave_producto: '',
				descripcion_concepto: '',
				numero_identificacion: '',
				cantidad: 1.0,
				precio_unitario: '',
				concepto: '',
				clave_concepto: '',
				tipo_descuento: '1',
				descuento: '',
				id_cat_impuesto_trasladado: '',
				id_cat_tipo_factor_trasladado: '',
				id_cat_impuesto: '',
				impuestos_trasladados: [],
				numero_pedimento: '',
				numero_predial: '',
				id_cat_objeto_impuesto: '',
				iva_incluido: false,
				iva_aplicado: false,
				iva_exento: false,
				editar_partida: 0,
				
				id_cat_impuesto_retenido: '',
				id_cat_tipo_factor_retenido: '',
				tasa_cuota_retenido: '',
				impuestos_traladados: [],
				impuestos_retenidos: [],
				partidas: partidas,
				autocomplete_concepto: {},
			}, () => {
			});
			
			setTimeout(() => {
				let desglose = this.calcularDesgoseTotales();
				this.setState({
					desglose: desglose
				});
			}, 800);
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	editarPartida = (value, key) => {
		try {
			console.log(value, key);
			this.setState({
				editar_partida: key + 1,
				id_empresa_clave_producto: value.id_empresa_clave_producto,
				numero_identificacion: value.numero_identificacion,
				id_empresa_clave_unidad: value.id_empresa_clave_unidad,
				unidad_medida: value.unidad_medida,
				clave_unidad: value.clave_unidad,
				unidad: value.unidad,
				descripcion: value.descripcion,
				cantidad: value.cantidad,
				precio_unitario: value.precio_unitario,
				importe: value.importe,
				concepto: value.concepto,
				clave_concepto: value.clave_concepto,
				tipo_descuento: value.tipo_descuento,
				descuento: value.descuento,
				numero_pedimento: value.numero_pedimento,
				numero_predial: value.numero_predial,
				id_cat_objeto_impuesto: value.id_cat_objeto_impuesto,
				trasladados: value.trasladados,
				retenidos: value.retenidos,
				impuestos_trasladados: value.impuestos_trasladados,
				impuestos_retenidos: value.impuestos_retenidos,
				iva_incluido: value.iva_incluido ? 1 : 0,
				iva_aplicado: value.iva_aplicado ? 1 : 0,
				iva_exento: value.iva_exento ? 1 : 0,
			}, () => {
				this.obtenerDatosConcepto();
			});
			
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminarPartida = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la partida?').then((r) => {
				if (r.button === 'Aceptar') {
					let partidas = FieldsJs.Copy(this.state.partidas);
					
					partidas.splice(key, 1);
					
					this.setState({
						partidas: partidas
					});
					
					setTimeout(() => {
						let desglose = this.calcularDesgoseTotales();
						this.setState({
							desglose: desglose
						});
					}, 800);
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	selectUsuario = (id_usuario) => {
		let user = '';
		for (let i = 0; i < this.state.cat_usuario.length; i++) {
			if (Number(id_usuario) === Number(this.state.cat_usuario[i].id_usuario)) {
				user = this.state.cat_usuario[i].nombre_completo;
			}
		}
		
		this.setState({
			usuario: user
		});
	}
	
	selectProveedor = (id_proveedor) => {
		let prov = '';
		let tarj = '';
		for (let i = 0; i < this.state.cat_proveedor.length; i++) {
			if (Number(id_proveedor) === Number(this.state.cat_proveedor[i].id_proveedor)) {
				prov = this.state.cat_proveedor[i].nombre_razon_social;
				tarj = this.state.cat_proveedor[i].cuenta_bancaria ? this.state.cat_proveedor[i].cuenta_bancaria.numero_tarjeta ? this.state.cat_proveedor[i].cuenta_bancaria.clabe : '' : '';
			}
		}
		
		this.setState({
			proveedor: prov,
			tarjeta: tarj
		});
	}
	
	selectTipoPago = (id_cat_tipo_pago) => {
		let tp = '';
		for (let i = 0; i < this.state.cat_tipo_pago.length; i++) {
			if (Number(id_cat_tipo_pago) === Number(this.state.cat_tipo_pago[i].id_cat_tipo_pago)) {
				tp = this.state.cat_tipo_pago[i].tipo_pago;
			}
		}
		this.setState({
			factura: Number(id_cat_tipo_pago) === 2 ? '1':'2',
			tipo_pago: tp
		});
	}
	
	selectBanco = (id_cat_banco) => {
		let bc = '';
		for (let i = 0; i < this.state.cat_banco.length; i++) {
			if (Number(id_cat_banco) === Number(this.state.cat_banco[i].id_cat_banco)) {
				bc = this.state.cat_banco[i].banco;
			}
		}
		this.setState({
			banco: bc
		});
	}
	
	handleChangeNoRecibo = (proyecto) => {
		this.concatTipoPago(proyecto);
	}
	
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_solicitud_pago}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Solicitud de Pago'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<FiberManualRecord className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="no_recibo"
										           onChange={this.handleChange}
										           label="No. Recibo" autoComplete={'off'}
										           value={this.state.no_recibo}
										           disabled
										           
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Usuario Solicitante</InputLabel>
											<Select
												native
												value={this.state.id_usuario}
												onChange={(e) => {
													this.handleChange(e);
													this.selectUsuario(e.target.value);
												}}
												name="id_usuario"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_usuario.map(option => (
													<option key={option.id_usuario}
													        value={option.id_usuario}>
														{option.nombre_completo}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Proyecto</InputLabel>
											<Select
												native
												value={this.state.id_cat_area_proyecto}
												onChange={(e) => {
													this.handleChange(e);
													this.handleChangeNoRecibo(e.target.value);
												}}
												name="id_cat_area_proyecto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_area_proyecto.map(option => (
													<option key={option.id_cat_area_proyecto}
													        value={option.id_cat_area_proyecto}>
														{option.area_proyecto}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Comment className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="comentarios"
										           onChange={this.handleChange}
										           label="Observaciones" autoComplete={'off'}
										           value={this.state.comentarios}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left borde-punteado-gray-2 margin-10-T'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<FiberManualRecord className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="descripcion"
												           onChange={this.handleChange}
												           label="Descripción" autoComplete={'off'}
												           value={this.state.descripcion}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Proveedor</InputLabel>
													<Select
														native
														value={this.state.id_proveedor}
														onChange={(e) => {
															this.handleChange(e);
															this.selectProveedor(e.target.value);
														}}
														name="id_proveedor"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_proveedor.map(option => (
															<option key={option.id_proveedor}
															        value={option.id_proveedor}>
																{option.nombre_razon_social}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="beneficiario"
												           onChange={this.handleChange}
												           label="Beneficiario de la cuenta" autoComplete={'off'}
												           value={this.state.beneficiario}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<FiberManualRecord className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cantidad"
												           onChange={this.handleChange}
												           label="Cantidad" autoComplete={'off'}
												           value={this.state.cantidad}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AttachMoney className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="precio_unitario"
												           onChange={this.handleChange}
												           label="Precio Unitario" autoComplete={'off'}
												           value={this.state.precio_unitario}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AttachMoney className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="importe"
												           onChange={this.handleChange}
												           label="Importe" autoComplete={'off'}
												           value={this.state.importe}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Tipo de Pago</InputLabel>
													<Select
														native
														value={this.state.id_cat_tipo_pago}
														onChange={(e) => {
															this.handleChange(e);
															this.selectTipoPago(e.target.value);
														}}
														name="id_cat_tipo_pago"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_tipo_pago.map(option => (
															<option key={option.id_cat_tipo_pago}
															        value={option.id_cat_tipo_pago}>
																{option.tipo_pago}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountBalance className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Factura</InputLabel>
													<Select
														native
														value={this.state.factura}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="factura"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														<option key={'1'} value={'1'}>Si</option>
														<option key={'2'} value={'2'}>No</option>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Banco</InputLabel>
													<Select
														native
														value={this.state.id_cat_banco}
														onChange={(e) => {
															this.handleChange(e);
															this.selectBanco(e.target.value);
														}}
														name="id_cat_banco"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_banco.map(option => (
															<option key={option.id_cat_banco}
															        value={option.id_cat_banco}>
																{option.banco}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<CreditCard className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="tarjeta"
												           onChange={this.handleChange}
												           label="No. Tarjeta / Clabe Interbancaria" autoComplete={'off'}
												           value={this.state.tarjeta}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item xs={12} sm={6} md={2} lg={1} xl={1} align={'right'}>
										<div className={'w-auto vertical-inline h-100'}>
											<div className={'v-center'}>
												<Tooltip
													TransitionComponent={Zoom}
													placement={"top"}
													title={"Agregar"}
													children={(
														<Fab size="small" color="secondary"
														     aria-label={"Agregar"}
														     onClick={() => this.agregarPartida()}
														     style={{marginLeft: "15px"}}>
															<AddOutlined/>
														</Fab>
													)}
												/>
											</div>
										</div>
									</Grid>
									
									{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox type="checkbox" name='activo'
															          checked={this.state.activo}
															          onChange={this.handleChange} value="activo"
															          color="primary"
															          disabled={this.props.tipo === 'view'}/>
														}
														label={'Activo'}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>*/}
								
								</Grid>
							</Grid>
							
							{this.state.partidas.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<table className={'desing-mark'}>
										<thead>
										<tr>
											<th align={"center"}>No.</th>
											<th align={"center"}>Descripción</th>
											<th align={"center"}>Proveedor</th>
											<th align={"center"}>Beneficiario</th>
											<th align={"center"}>Cantidad</th>
											<th align={"center"}>Precio Unitario</th>
											<th align={"center"}>Importe</th>
											<th align={"center"}>Tipo Pago</th>
											<th align={"center"}>Factura</th>
											<th align={"center"}>Banco</th>
											<th align={"center"}>Tarjeta</th>
											<th align={"right"}>Acciones</th>
										</tr>
										</thead>
										<tbody>
										{this.state.partidas.map((item, index) => (
												<tr key={index}>
													<td align={"center"}>{index + 1}</td>
													<td align={"center"}>{item.descripcion}</td>
													<td align={"center"}>{item.proveedor}</td>
													<td align={"center"}>{item.beneficiario}</td>
													<td align={"center"}>{item.cantidad}</td>
													<td align={"center"}>{item.precio_unitario}</td>
													<td align={"center"}>{item.importe}</td>
													<td align={"center"}>{item.tipo_pago}</td>
													<td align={"center"}>{item.factura}</td>
													<td align={"center"}>{item.banco}</td>
													<td align={"center"}>{item.tarjeta}</td>
													<td align={'right'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Eliminar"
															children={(
																<Fab
																	size="small"
																	aria-label="Eliminar"
																	onClick={() => this.eliminarPartida(item, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<DeleteOutlined/>
																	)}
																/>
															)}
														/>
													</td>
												</tr>
											)
										)}
										</tbody>
									</table>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={0}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											//open={this.state.open}
											filesLimit={1}
											dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
											acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_solicitud_pago}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSolicitudAceptada.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	ListUsuario: PropTypes.func.isRequired,
	ListProveedor: PropTypes.func.isRequired,
	ListBanco: PropTypes.func.isRequired,
	ListTipoPago: PropTypes.func.isRequired,
	ListAreaProyecto: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSolicitudAceptada;
