import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {SolicitudPagoService} from "../../../../services/_Sis/SolicitudPagoService/SolicitudPagoService";
import ModalSolicitudPago from "./ModalSolicitudPago";
import {CONFIG} from "../../../../settings/Config/Config";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import SubTablaSolicitudPago from "./SubTablaSolicitudPago";
import ModalDatos from "./ModalDatos";
import {Description, Folder, HdrWeakOutlined, SearchOutlined} from "@material-ui/icons";
import ModalEstatus from "./ModalEstatus";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la cuenta bancaria del beneficiario: ${item.nombre_beneficiario.toUpperCase()}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				SolicitudPagoService.Eliminar(item.id_solicitud_pago).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	ver_admin = () => {
		return this.Usr.id_usuario === 2 || this.Usr.id_usuario === 1;
	};
	
	
	is_cancel = (item) => {
		return item.id_cat_estatus_solicitud_pago === 3;
	};
	
	
	render() {
		
		const {lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'}>...</TableCell>
							<TableCell component="th" align={'left'}>Id</TableCell>
							<TableCell component="th" align={'left'}>No. Recibo</TableCell>
							<TableCell component="th" align={'left'}>Usuario Solicitante</TableCell>
							<TableCell component="th" align={'left'}>Proyecto</TableCell>
							<TableCell component="th" align={'left'}>Etapa</TableCell>
							<TableCell component="th" align={'left'}>Sub-Etapa</TableCell>
							<TableCell component="th" align={'left'}>Clase</TableCell>
							<TableCell component="th" align={'left'}>Sub-Clase</TableCell>
							<TableCell component="th" align={'left'}>Estatus</TableCell>
							<TableCell component="th" align={'left'}>Observaciones</TableCell>
							<TableCell component="th" align={'center'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<Fragment key={key}>
							<TableRow>
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									{item.partidas.length > 0 ? (
										<div className={'w-100'} align={'center'}>
											<div style={!!item.expandir ? bg_menos : bg_mas}
											     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
											     onClick={() => this.props.SubTabla(item, key)}
											/>
										</div>
									) : '...'}
								</TableCell>
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.id_solicitud_pago}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.no_recibo}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.nombre_completo}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.area_proyecto}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.etapa}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.subetapa}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.clase}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.subclase}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.estatus}</TableCell>
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>{item.comentarios}</TableCell>
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<div className={'w-auto vertical-inline'}>
										{this.ver_admin() ? (
											<div className={'v-center'}>
												<ModalEstatus
													tipo={'edit'}
													item={item}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
															<IconButton aria-label="Estatus">
																<HdrWeakOutlined/>
															</IconButton>
														</Tooltip>
													}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
										):null}
										
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalSolicitudPago
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
												ListUsuario={this.props.ListUsuario}
												ListTipoPago={this.props.ListTipoPago}
												ListProveedor={this.props.ListProveedor}
												ListBanco={this.props.ListBanco}
												ListAreaProyecto={this.props.ListAreaProyecto}
												ListEtapaProyecto={this.props.ListEtapaProyecto}
												ListSubEtapaProyecto={this.props.ListSubEtapaProyecto}
												ListClase={this.props.ListClase}
												ListSubClase={this.props.ListSubClase}
											/>
										</div>
										<div className={'v-center'}>
											<ModalSolicitudPago
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
												ListUsuario={this.props.ListUsuario}
												ListTipoPago={this.props.ListUsuario}
												ListProveedor={this.props.ListProveedor}
												ListBanco={this.props.ListBanco}
												ListAreaProyecto={this.props.ListAreaProyecto}
												ListEtapaProyecto={this.props.ListEtapaProyecto}
												ListSubEtapaProyecto={this.props.ListSubEtapaProyecto}
												ListClase={this.props.ListClase}
												ListSubClase={this.props.ListSubClase}
											/>
										</div>
										{this.ver_admin() ? (
											<div className={'v-center'}>
												<ModalDatos
													tipo={'view'}
													item={item}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalle">
															<IconButton aria-label="Detalle">
																<Description/>
															</IconButton>
														</Tooltip>
													}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
													ListUsuario={this.props.ListUsuario}
													ListTipoPago={this.props.ListUsuario}
													ListProveedor={this.props.ListProveedor}
													ListBanco={this.props.ListBanco}
													ListAreaProyecto={this.props.ListAreaProyecto}
													ListEtapaProyecto={this.props.ListEtapaProyecto}
													ListSubEtapaProyecto={this.props.ListSubEtapaProyecto}
													ListClase={this.props.ListClase}
													ListSubClase={this.props.ListSubClase}
												/>
											</div>
										):null}
										
									</div>
								</TableCell>
							</TableRow>
								{(
									<Fragment>
										{!!item.expandir ? (
											<TableRow>
												<TableCell align={'center'}
												           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
												           colSpan={this.ver_root() ? 17 : 17}
												>
													
													<SubTablaSolicitudPago
														tipo_vista={this.props.tipo_vista}
														item={{lista: item.partidas}}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
													/>
												
												</TableCell>
											</TableRow>
										) : null}
									</Fragment>
								)}
							</Fragment>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
	ListUsuario: PropTypes.func.isRequired,
	ListTipoPago: PropTypes.func.isRequired,
	ListProveedor: PropTypes.func.isRequired,
	ListBanco: PropTypes.func.isRequired,
	ListAreaProyecto: PropTypes.func.isRequired,
	ListEtapaProyecto: PropTypes.func.isRequired,
	ListSubEtapaProyecto: PropTypes.func.isRequired,
	ListClase: PropTypes.func.isRequired,
	ListSubClase: PropTypes.func.isRequired,
};

export default ListaTabla;
