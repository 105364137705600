import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';

import App from './componets/App';
import LogInIndex from './componets/PageFull/LogIn';
import RegistroIndex from './componets/PageFull/Registro';
import RegistroDatosPersonalesIndex from './componets/PageFull/RegistroDatosPersonales';
import RecuperarIndex from './componets/PageFull/Recuperar';
import VerificarIndex from './componets/PageFull/Verificar';
import CambiarContrasenaIndex from './componets/PageFull/CambiarContrasena';
import CatalogosIndex from './componets/PageContent/Catalogos';
import SexoIndex from './componets/PageContent/Cat/Sexo';
import TipoUsuarioIndex from './componets/PageContent/Cat/TipoUsuario';
import MenuSubMenuIndex from './componets/PageContent/Cat/MenuSubMenu';
import RolesPermisosIndex from './componets/PageContent/Cat/RolesPermisos';
import UsuariosIndex from './componets/PageContent/Usuarios';
import HomeIndex from './componets/PageContent/Home';
import PerfilIndex from './componets/PageContent/Perfil';
import AreaProyectoIndex from './componets/PageContent/Cat/AreaProyecto';
import ClaseIndex from './componets/PageContent/Cat/Clase';
import SubClaseIndex from './componets/PageContent/Cat/SubClase';
import TipoGastoIndex from './componets/PageContent/Cat/TipoGasto';
import TipoPolizaIndex from './componets/PageContent/Cat/TipoPoliza';
import TipoCuentaIndex from './componets/PageContent/Cat/TipoCuenta';
import BancosIndex from './componets/PageContent/Cat/Banco';
import EtapaProyectoIndex from './componets/PageContent/Cat/EtapaProyecto';
import BancosSatIndex from './componets/PageContent/Cat/BancoSat';
import ProveedorIndex from './componets/PageContent/Proveedor';
import CuentaBancariaIndex from './componets/PageContent/CuentaBancaria';
import NominaIndex from './componets/PageContent/Nomina';
import SolicitudPagoIndex from './componets/PageContent/SolicitudPago';
import SolicitudAceptadaIndex from './componets/PageContent/SolicitudAceptada';
import Page404Index from './componets/PageFull/Page404';

import EventListenerClickDom from './componets/Include/EventListenerClickDom/EventListenerClickDom';



class AppRoutes extends Component {
	
	render() {
		return (
			<Fragment>
				<EventListenerClickDom/>
				<App>
					<Switch>
						<Route exact path="/login" component={LogInIndex}/>
						<Route exact path="/registro" component={RegistroIndex}/>
						<Route exact path="/registrodatospersonales" component={RegistroDatosPersonalesIndex}/>
						<Route exact path="/recuperar" component={RecuperarIndex}/>
						<Route exact path="/verificar" component={VerificarIndex}/>
						<Route exact path="/cambiarcontrasena" component={CambiarContrasenaIndex}/>
						<Route exact path="/catalogo" component={CatalogosIndex}/>
						<Route exact path="/genero" component={SexoIndex}/>
						<Route exact path="/tipousuario" component={TipoUsuarioIndex}/>
						<Route exact path="/menus" component={MenuSubMenuIndex}/>
						<Route exact path="/rolespermisos" component={RolesPermisosIndex}/>
						<Route exact path="/usuario" component={UsuariosIndex}/>
						<Route exact path="/home" component={HomeIndex}/>
						<Route exact path="/perfil" component={PerfilIndex}/>
						
						<Route exact path="/etapaproyecto" component={EtapaProyectoIndex}/>
						<Route exact path="/areaproyecto" component={AreaProyectoIndex}/>
						<Route exact path="/clase" component={ClaseIndex}/>
						<Route exact path="/subclase" component={SubClaseIndex}/>
						<Route exact path="/tipogasto" component={TipoGastoIndex}/>
						<Route exact path="/tipopoliza" component={TipoPolizaIndex}/>
						<Route exact path="/tipocuenta" component={TipoCuentaIndex}/>
						<Route exact path="/bancos" component={BancosIndex}/>
						<Route exact path="/bancossat" component={BancosSatIndex}/>
						<Route exact path="/proveedor" component={ProveedorIndex}/>
						<Route exact path="/cuentabancaria" component={CuentaBancariaIndex}/>
						<Route exact path="/nomina" component={NominaIndex}/>
						<Route exact path="/solicitudespago" component={SolicitudPagoIndex}/>
						<Route exact path="/solicitudes" component={SolicitudAceptadaIndex}/>
						
						<Route exact path="/" component={LogInIndex}/>
						<Route component={Page404Index}/>
					</Switch>
				</App>
			</Fragment>
		);
	}
}

export default AppRoutes;
