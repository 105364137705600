import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SolicitudPagoService} from "../../../../services/_Sis/SolicitudPagoService/SolicitudPagoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance,
	HdrWeakOutlined,
	Person,
	Comment,
	FiberManualRecord,
	AccountTree,
	DeleteOutlined,
	AddOutlined, AttachMoney, CreditCard
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Zoom from '@material-ui/core/Zoom/index';
import Button from "@material-ui/core/Button";
import {DropzoneArea} from "material-ui-dropzone";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";


class ModalSolicitudPago extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_solicitud_pago: props.item.id_solicitud_pago || '',
			id_cat_estatus_solicitud_pago: '',
			cat_estatus_solicitud_pago: [],
			solicitud_pago_estatus: [],
			comentarios: '',
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	ListCatEstatusSolicituPago = () => {
		return new Promise((resolve) => {
			CatService.ListCatEstatusSolicitudPago().then((response) => {
				this.setState({
					cat_estatus_solicitud_pago:response.data
				});
				resolve(response);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	}
	
	ListEstatusSolicituPago = () => {
		const {item} = this.props;
		
		return new Promise((resolve) => {
			CatService.ListEstatusSolicitudPago(item.id_solicitud_pago).then((response) => {
				this.setState({
					solicitud_pago_estatus:response.data
				});
				resolve(response);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	}
	
	
	save = () => {
		let msg = `¿Deseas modificar el estatus de la solicitud de pago?`;
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Aceptar') {
				SolicitudPagoService.EstatusAgregar(this.state).then((response) => {
					this.close();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	
	edit = () => {
		const {item} = this.props;
		
		this.ListCatEstatusSolicituPago();
		this.ListEstatusSolicituPago();
		
		this.setState({
			id_solicitud_pago: item.id_solicitud_pago || '',
			id_cat_estatus_solicitud_pago: item.id_cat_estatus_solicitud_pago || '',
			comentarios: '',
			
			activo: true
		});
		
		this.open();
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_solicitud_pago}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Estatus'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<RadioGroup
									row
									aria-label="position"
									name="id_cat_estatus_solicitud_pago"
									value={this.state.id_cat_estatus_solicitud_pago.toString()}
									onChange={(e) => {
										this.handleChange(e);
									}}>
									
									{this.state.cat_estatus_solicitud_pago && this.state.cat_estatus_solicitud_pago.map((value, index) => (
										<FormControlLabel
											key={index}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={value.id_cat_estatus_solicitud_pago.toString()}
											label={value.estatus}
										/>
									))}
								</RadioGroup>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Comment className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="comentarios"
										           onChange={this.handleChange}
										           label="Observaciones" autoComplete={'off'}
										           value={this.state.comentarios}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							{this.state.solicitud_pago_estatus.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<table className={'desing-acresco'}>
										<thead>
										<tr>
											<th align={"left"}>Usuario</th>
											<th align={"left"}>Estatus</th>
											<th align={"left"}>Fecha</th>
											<th align={"left"}>Observación</th>
										</tr>
										</thead>
										<tbody>
										{this.state.solicitud_pago_estatus.map((item, index) => (
												<tr key={index}>
													<td align={"left"}>{item.nombre_completo}</td>
													<td align={"left"}>{item.estatus}</td>
													<td align={"left"}>{DateFormat.getFechaText(item.created_at)}</td>
													<td align={"left"}>{item.comentarios}</td>
												</tr>
											)
										)}
										</tbody>
									</table>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_solicitud_pago}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSolicitudPago.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSolicitudPago;
