import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SolicitudPagoService} from "../../../../services/_Sis/SolicitudPagoService/SolicitudPagoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance,
	HdrWeakOutlined,
	Person,
	Comment,
	FiberManualRecord,
	AccountTree,
	DeleteOutlined,
	AddOutlined, AttachMoney, CreditCard
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Zoom from '@material-ui/core/Zoom/index';
import Button from "@material-ui/core/Button";


class ModalSolicitudPago extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_solicitud_pago: props.item.id_solicitud_pago || '',
			no_recibo: '',
			id_usuario: '',
			id_cat_area_proyecto: '',
			id_cat_etapa_proyecto: '',
			id_cat_subetapa_proyecto: '',
			descripcion: '',
			id_proveedor: '',
			beneficiario: '',
			cantidad: '',
			precio_unitario: '',
			importe: '',
			id_cat_tipo_pago: '',
			factura: '',
			id_cat_banco: '',
			tarjeta: '',
			usuario: '',
			banco: '',
			proveedor: '',
			tipo_pago: '',
			comentarios: '',
			
			partidas: [],
			cat_usuario: [],
			cat_tipo_pago: [],
			cat_banco: [],
			cat_area_proyecto: [],
			cat_etapa_proyecto: [],
			cat_subetapa_proyecto: [],
			cat_proveedor: [],
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	view = () => {
		const {item} = this.props;
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
			});
		});
		
		this.props.ListUsuario().then((response) => {
			this.setState({
				cat_usuario: response.data,
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
			});
		});
		
		this.props.ListTipoPago().then((response) => {
			this.setState({
				cat_tipo_pago: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
			});
		});
		
		this.props.ListEtapaProyecto().then((response) => {
			this.setState({
				cat_etapa_proyecto: response.data,
			});
		});
		
		this.props.ListSubEtapaProyecto().then((response) => {
			this.setState({
				cat_subetapa_proyecto: response.data,
			});
		});
		
		
		this.setState({
			id_solicitud_pago: item.id_solicitud_pago || '',
			no_recibo: item.no_recibo || '',
			id_usuario: item.id_usuario || '',
			id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
			id_cat_subetapa_proyecto: item.id_cat_subetapa_proyecto || '',
			comentarios: item.comentarios || '',
			partidas: item.partidas || [],
			
			activo: true
		});
		
		this.open();
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el contacto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.lista_contactos);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						lista_contactos: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	agregarPartida = () => {
		try {
			
			/*if (!this.state.clave_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave Concepto.'
				})
			}*/
			
			let partidas = FieldsJs.Copy(this.state.partidas);
			//let productos = FieldsJs.Copy(this.state.listar_concepto);
			
			/*for (let i = 0; i < cat_impuesto.length; i++) {
				if (Number(3) === Number(cat_impuesto[i].id_cat_impuesto)) {
					im = cat_impuesto[i].impuesto;
				}
			}
			*/
			let item = {
				descripcion: this.state.descripcion || '',
				id_proveedor: this.state.id_proveedor || '',
				cantidad: this.state.cantidad || '',
				precio_unitario: this.state.precio_unitario || '',
				importe: this.state.importe || '',
				id_cat_tipo_pago: this.state.id_cat_tipo_pago || '',
				factura: this.state.factura || '',
				id_cat_banco: this.state.id_cat_banco || '',
				tarjeta: this.state.tarjeta || '',
				banco: this.state.banco || '',
				proveedor: this.state.proveedor || '',
				beneficiario: this.state.beneficiario || '',
				tipo_pago: this.state.tipo_pago || '',
			};
			
			partidas.push(item);
			
			this.setState({
				
				descripcion: '',
				id_proveedor: '',
				cantidad: '',
				precio_unitario: '',
				importe: '',
				id_cat_tipo_pago: '',
				factura: '',
				id_cat_banco: '',
				tarjeta: '',
				banco: '',
				proveedor: '',
				beneficiario: '',
				tipo_pago: '',
				partidas: partidas,
			});
			
			setTimeout(() => {
				let desglose = this.calcularDesgoseTotales();
				this.setState({
					desglose: desglose
				});
			}, 800);
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	selectUsuario = (id_usuario) => {
		let user = '';
		for (let i = 0; i < this.state.cat_usuario.length; i++) {
			if (Number(id_usuario) === Number(this.state.cat_usuario[i].id_usuario)) {
				user = this.state.cat_usuario[i].nombre_completo;
			}
		}
		
		this.setState({
			usuario: user
		});
	}
	
	selectProveedor = (id_proveedor) => {
		let prov = '';
		let tarj = '';
		for (let i = 0; i < this.state.cat_proveedor.length; i++) {
			if (Number(id_proveedor) === Number(this.state.cat_proveedor[i].id_proveedor)) {
				prov = this.state.cat_proveedor[i].nombre_razon_social;
				tarj = this.state.cat_proveedor[i].cuenta_bancaria ? this.state.cat_proveedor[i].cuenta_bancaria.numero_tarjeta ? this.state.cat_proveedor[i].cuenta_bancaria.clabe : '' : '';
			}
		}
		
		this.setState({
			proveedor: prov,
			tarjeta: tarj
		});
	}
	
	selectTipoPago = (id_cat_tipo_pago) => {
		let tp = '';
		for (let i = 0; i < this.state.cat_tipo_pago.length; i++) {
			if (Number(id_cat_tipo_pago) === Number(this.state.cat_tipo_pago[i].id_cat_tipo_pago)) {
				tp = this.state.cat_tipo_pago[i].tipo_pago;
			}
		}
		this.setState({
			factura: Number(id_cat_tipo_pago) === 2 ? '1':'2',
			tipo_pago: tp
		});
	}
	
	selectBanco = (id_cat_banco) => {
		let bc = '';
		for (let i = 0; i < this.state.cat_banco.length; i++) {
			if (Number(id_cat_banco) === Number(this.state.cat_banco[i].id_cat_banco)) {
				bc = this.state.cat_banco[i].banco;
			}
		}
		this.setState({
			banco: bc
		});
	}
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_solicitud_pago}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Solicitud de Pago'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<strong>No. Recibo:</strong>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										{this.state.no_recibo}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<strong>Usuario Solicitante:</strong>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										{this.state.cat_usuario.map((option) => {
											return option.id_usuario === this.state.id_usuario ? option.nombre_completo : null
										})}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<strong>Proyecto:</strong>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										{this.state.cat_area_proyecto.map((option) => {
											return option.id_cat_area_proyecto === this.state.id_cat_area_proyecto ? option.area_proyecto : null
										})}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<strong>Etapa:</strong>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										{this.state.cat_etapa_proyecto.map((option) => {
											return option.id_cat_etapa_proyecto === this.state.id_cat_etapa_proyecto ? option.etapa_proyecto : null
										})}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<strong>Sub-Etapa:</strong>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										{this.state.cat_subetapa_proyecto.map((option) => {
											return option.id_cat_subetapa_proyecto === this.state.id_cat_subetapa_proyecto ? option.subetapa_proyecto : null
										})}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<strong>Observaciones:</strong>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										{this.state.comentarios}
									</Grid>
								</Grid>
							</Grid>
							
							{this.state.partidas.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<table className={'desing-mark'}>
										<thead>
										<tr>
											<th align={"center"}>No.</th>
											<th align={"center"}>Descripción</th>
											<th align={"center"}>Proveedor</th>
											<th align={"center"}>Beneficiario</th>
											<th align={"center"}>Cantidad</th>
											<th align={"center"}>Precio Unitario</th>
											<th align={"center"}>Importe</th>
											<th align={"center"}>Tipo Pago</th>
											<th align={"center"}>Factura</th>
											<th align={"center"}>Banco</th>
											<th align={"center"}>Tarjeta</th>
										</tr>
										</thead>
										<tbody>
										{this.state.partidas.map((item, index) => (
												<tr key={index}>
													<td align={"center"}>{index + 1}</td>
													<td align={"center"}>{item.descripcion}</td>
													<td align={"center"}>{item.proveedor}</td>
													<td align={"center"}>{item.beneficiario}</td>
													<td align={"center"}>{item.cantidad}</td>
													<td align={"center"}>{item.precio_unitario}</td>
													<td align={"center"}>{item.importe}</td>
													<td align={"center"}>{item.tipo_pago}</td>
													<td align={"center"}>{item.factura}</td>
													<td align={"center"}>{item.banco}</td>
													<td align={"center"}>{item.tarjeta}</td>
												</tr>
											)
										)}
										</tbody>
									</table>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						<Button onClick={this.close} color="primary">
							{'Cerrar'}
						</Button>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSolicitudPago.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	ListUsuario: PropTypes.func.isRequired,
	ListProveedor: PropTypes.func.isRequired,
	ListBanco: PropTypes.func.isRequired,
	ListTipoPago: PropTypes.func.isRequired,
	ListAreaProyecto: PropTypes.func.isRequired,
	ListEtapaProyecto: PropTypes.func.isRequired,
	ListSubEtapaProyecto: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSolicitudPago;
